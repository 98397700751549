import { AppProps } from 'next/app'
// eslint-disable-next-line import/no-unassigned-import
import '@/styles/global.scss'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { getConfigData } from '@/helper/get-config-data'

export default function App(props: AppProps) {
  const { Component } = props
  const config = getConfigData()

  const router = useRouter()

  useEffect(() => {
    const routeEndAction = () => {
      document.body.classList.remove('fade-out')
    }

    router.events.on('routeChangeComplete', routeEndAction)
    router.events.on('routeChangeError', routeEndAction)

    return () => {
      router.events.off('routeChangeStart', routeEndAction)
      router.events.off('routeChangeError', routeEndAction)
    }
  })

  useEffect(() => {
    if (window.location.hash.includes('_token=')) {
      window.location.replace('/cms' + window.location.hash)
    }
  })

  return (
    <>
      <Head>
        <title>{config.siteTitle}</title>
      </Head>
      <div className="responsive-wrap">
        <Component {...props.pageProps} />
      </div>
    </>
  )
}
